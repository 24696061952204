





















































































































































import { api } from "@/api/api";
import { ApiCreateCourseInvitationDto } from "@/api/generated/Api";
import CourseAddPersonInviteModal from "@/components/course/details/CourseAddPersonInviteModal.vue";
import BaseConfirmDialog from "@/components/shared/BaseConfirmDialog.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import BaseSheetHeader from "@/components/shared/sheet/BaseSheetHeader.vue";
import BaseTableSimple from "@/components/shared/table/BaseTableSimple.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { CourseInvitationType } from "@/shared/enums/CourseInvitationType.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { SingleCourseRouteNames } from "@/shared/enums/RouteNames/singleCourseRouteNames.enum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useNavigateBack } from "@/shared/helpers/navigationHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { InvitePerson } from "@/shared/interfaces/InvitePerson.interface";
import { useRoute, useRouter, useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";

export default defineComponent({
  name: "CourseInvitesNewPage",
  components: {
    BaseTableSimple,
    CourseAddPersonInviteModal,
    BaseModal,
    BaseSheet,
    BaseSheetHeader,
    BaseConfirmDialog,
    BaseLayout,
  },

  setup() {
    const store = useStore<StoreState>();
    const selectedFile = ref<File>();
    const peopleToInvite = ref<InvitePerson[]>([]);
    const modalData = ref(getInitialModalData());
    const route = useRoute();
    const router = useRouter();
    const selectAllCreateRestrictedUser = ref(false);
    const selectAllSendEmail = ref(false);
    const selectAllSendSms = ref(false);

    watch(peopleToInvite, () => updateHeaderCheckboxes());

    onMounted(() => {
      updateHeaderCheckboxes();
    });

    const updateHeaderCheckboxes = () => {
      const filterInvites = (field: keyof typeof CourseInvitationType) =>
        !!peopleToInvite.value?.length &&
        !peopleToInvite.value?.filter((person) => !person[CourseInvitationType[field]])?.length;

      selectAllCreateRestrictedUser.value = filterInvites("CreateRestrictedUser");
      selectAllSendEmail.value = filterInvites("SendEmail");
      selectAllSendSms.value = filterInvites("SendSms");
    };

    const onFileChange = () => {
      if (!selectedFile.value) {
        return;
      }
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          if (typeof reader.result === "string") {
            peopleToInvite.value = getUsersFromFile(reader.result);
            updateHeaderCheckboxes();
          }
        },
        false
      );
      reader.readAsText(selectedFile.value);
    };

    const getUsersFromFile = (fileContent: string): InvitePerson[] => {
      const lines = fileContent.split("\n");
      const [headerLine, ...dataLines] = lines;
      if (dataLines.length === 0 || headerLine.trim() !== "fornavn;etternavn;epost;mobil") {
        openNotification(store, NotificationItemType.Error, "Feil under parsing av CSV-fil");
        return [];
      }

      return dataLines
        .filter((line) => {
          const [firstName, lastName, email, mobile] = line.split(";");
          if (
            firstName?.trim().length > 0 &&
            lastName?.trim().length > 0 &&
            email?.trim().length > 0 &&
            mobile?.trim().length > 0
          ) {
            return true;
          }
          if (!firstName && !lastName && !email && !mobile) {
            return false;
          }
          openNotification(
            store,
            NotificationItemType.Warning,
            "En linje mangler navn, epost eller mobilnummer så den hoppes over"
          );
          return false;
        })
        .map((line) => {
          const [firstName, lastName, email, mobile] = line.split(";");
          return {
            firstName,
            lastName,
            email,
            mobile: mobile.trim(),
            createRestrictedUser: false,
            sendEmail: true,
            sendSms: true,
            uuid: generateUuid(),
          };
        });
    };

    const deleteInvite = (item: InvitePerson) => {
      peopleToInvite.value = peopleToInvite.value.filter((current) => current.uuid !== item.uuid);
      updateHeaderCheckboxes();
    };

    const openAddNewPerson = useOpenModal(ModalType.Add, "person", modalData);

    const addPerson = (person: InvitePerson) => {
      peopleToInvite.value.push(person);
      updateHeaderCheckboxes();
    };

    const clearPeopleList = () => {
      peopleToInvite.value = [];
      selectedFile.value = undefined;
      updateHeaderCheckboxes();
    };

    const getInvitePersonByUuid = (uuid: string) => peopleToInvite.value.find((person) => person.uuid === uuid);

    const onChangeSelectInvitationType = (invitationType: CourseInvitationType, person: InvitePerson) => {
      const selectedPerson = getInvitePersonByUuid(person.uuid);
      if (!selectedPerson) {
        return;
      }
      if (invitationType === CourseInvitationType.CreateRestrictedUser && selectedPerson.createRestrictedUser) {
        selectedPerson.sendEmail = false;
        selectedPerson.sendSms = false;
      }
      if (invitationType === CourseInvitationType.SendEmail || invitationType === CourseInvitationType.SendSms) {
        selectedPerson.createRestrictedUser = false;
      }
      updateHeaderCheckboxes();
    };

    const onChangeSelectAllInvitationType = (invitationType: CourseInvitationType, isSelectedAll: boolean) => {
      if (invitationType === CourseInvitationType.CreateRestrictedUser) {
        peopleToInvite.value.map((person) => {
          person.createRestrictedUser = isSelectedAll;
          if (isSelectedAll) {
            person.sendEmail = false;
            person.sendSms = false;
          }
        });
        selectAllSendEmail.value = isSelectedAll ? false : selectAllSendEmail.value;
        selectAllSendSms.value = isSelectedAll ? false : selectAllSendSms.value;
      }
      if (invitationType === CourseInvitationType.SendEmail || invitationType === CourseInvitationType.SendSms) {
        peopleToInvite.value.map((person) => {
          if (invitationType === CourseInvitationType.SendEmail) {
            person.sendEmail = isSelectedAll;
          } else {
            person.sendSms = isSelectedAll;
          }
          if (isSelectedAll) {
            person.createRestrictedUser = false;
          }
        });
        selectAllCreateRestrictedUser.value = isSelectedAll ? false : selectAllCreateRestrictedUser.value;
      }
    };

    const sendInvites = () => {
      globalLoadingWrapper({ blocking: true }, async () => {
        const mappedPeople: ApiCreateCourseInvitationDto[] = peopleToInvite.value.map((person) => ({
          firstName: person.firstName,
          lastName: person.lastName,
          emailAddress: person.email,
          phoneNumber: person.mobile,
          createRestrictedUser: person.createRestrictedUser,
          sendEmail: person.sendEmail,
          sendSms: person.sendSms,
        }));

        await api.course.createInvitationsAsync(+route.params.id, mappedPeople);
        openNotification(store, NotificationItemType.Success, "Invitasjonene er sendt til alle personer i listen");
        router.push({
          name: SingleCourseRouteNames.CourseInvites,
          params: {
            id: route.params.id,
          },
        });
      });
    };

    return {
      course: computed(() => store.state.courses.course),
      modalData,
      selectedFile,
      onFileChange,
      headers,
      peopleToInvite,
      deleteInvite,
      openAddNewPerson,
      addPerson,
      clearPeopleList,
      sendInvites,
      CourseInvitationType,
      onChangeSelectInvitationType,
      onChangeSelectAllInvitationType,
      selectAllCreateRestrictedUser,
      selectAllSendEmail,
      selectAllSendSms,
      navigateToCourseInvites: () => useNavigateBack(SingleCourseRouteNames.CourseDashboard),
    };
  },
});

const headers = [
  {
    text: "Fornavn",
    value: "firstName",
  },
  {
    text: "Etternavn",
    value: "lastName",
  },
  {
    text: "E-post",
    value: "email",
  },
  {
    text: "Mobilnummer (med landskode)",
    value: "mobile",
  },
  {
    text: "Begrenset Bruker",
    value: "createRestrictedUser",
    sortable: false,
  },
  {
    text: "Send E-post",
    value: "sendEmail",
    sortable: false,
  },
  {
    text: "Send SMS",
    value: "sendSms",
    sortable: false,
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
];
