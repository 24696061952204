






















import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { generateUuid } from "@/shared/helpers/uuidHelpers";
import { validateIsEmail, validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { InvitePerson } from "@/shared/interfaces/InvitePerson.interface";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, ref } from "@vue/composition-api";

export default defineComponent({
  name: "CourseAddPersonInviteModal",
  components: { BaseModalForm },
  emits: ["close", "newPerson"],
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      validator: (value: string) => (Object.values(ModalType) as string[]).includes(value),
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();

    const personModel = ref<InvitePerson>(getPersonInitialData(store.state.hoc.usePreFilledValues));

    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (!isValid) {
        return;
      }
      if (props.modalType === ModalType.Add) {
        emit("newPerson", personModel.value);
        emit("close");
      }
    };

    return {
      personModel,
      validateNotEmpty,
      validateIsEmail,
      handleSubmit,
    };
  },
});

const getPersonInitialData = (usePreFilledValues: boolean): InvitePerson => {
  if (usePreFilledValues) {
    return {
      firstName: "Greger",
      lastName: "Hemb",
      email: "jan.g@h.g",
      mobile: "45678954",
      createRestrictedUser: false,
      sendEmail: true,
      sendSms: true,
      uuid: generateUuid(),
    };
  }
  return {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    createRestrictedUser: false,
    sendEmail: true,
    sendSms: true,
    uuid: generateUuid(),
  };
};
